import React, { Component } from "react";
import Slider from "react-slick";
import '../../components/product-carousel/style.scss'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { isEmpty } from "lodash";

export default class ProductCarousel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null
		};
	}

	componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }


render() {
	if ( isEmpty( this.props.galleryImages?.nodes ) ) {
		return null;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true
	};
	return (
<>
			<Slider {...settings}
			asNavFor={this.state.nav2}
			ref={slider => (this.slider1 = slider)}
			>
				{ this.props.galleryImages.nodes.map( galleryImage => {
					return (
						<div key={ galleryImage?.id } className="top-product-carousel">
							<div className="carousel-img-wrap carousel-img-wrap--product">
								{ !isEmpty( galleryImage.mediaItemUrl ) ? (
									<Zoom>
										<img
											src={ galleryImage?.mediaItemUrl }
											alt={
												!isEmpty( galleryImage?.altText )
													? galleryImage.altText
													: galleryImage?.title
											}
										/>
									</Zoom>
								) : null }
							</div>
						</div>
					)
				} ) }
			</Slider>

			<br />
			<br />
			<Slider
				asNavFor={this.state.nav1}
				ref={slider => (this.slider2 = slider)}
				slidesToShow={2}
				swipeToSlide={true}
				focusOnSelect={false}
				arrows={true}
			>

			{ this.props.galleryImages.nodes.map( galleryImage => {
				return (
					<div key={ galleryImage?.id }>
						<div className="">
							{ !isEmpty( galleryImage.mediaItemUrl ) ? (

									<img
										src={ galleryImage?.mediaItemUrl }
										alt={
											!isEmpty( galleryImage?.altText )
												? galleryImage.altText
												: galleryImage?.title
										}
									/>

							) : null }
						</div>
					</div>
				)
			} ) }
			</Slider>



</>
	);
};
}
