import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import AddToCartButton from "./cart/add-to-cart-button";

const SliderDrinks = () => {

  const {
    allWpProduct,
  } = useStaticQuery(graphql`
    query spropl24Query {
    allWpProduct(filter: {productCategories: {nodes: {elemMatch: {slug: {eq: "napoje-grzybowe-i-roslinne"}}}}}) {
      edges {
        node {
          databaseId
          sku
          name
          link
          currencyPrice
          ... on WpSimpleProduct {
            name
            featuredImage {
              node {
                altText
                localFile {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            price
          }
        }
      }
    }
  }

  `)


  return (
    <>
      <Slider
        slidesToShow={2}
        swipeToSlide={true}
        focusOnSelect={false}
        arrows={false}
        dots={true}
        infinite={true}
        speed={4000}
        initialSlide={2}
        autoplay={true}
        responsive= {[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      ]}
      >

      {allWpProduct.edges.map(post => {
        return (
        <>
        <div className="mush" style={{textAlign:'center'}}>
        <Link to={post.node.link}>
        <GatsbyImage
          image={post.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          alt={post.node.featuredImage.node.altText ? post.node.featuredImage.node.altText : post.node.name}
          style={{ marginBottom: 15 }}
          imgStyle={{width:'100%'}}
          loading="lazy"
        />
        <p className="spec-h slider-p" style={{fontSize:'1.1em',width:'250px'}}>{post.node.name}</p>
        </Link>
        <br />
        <p className="card-subtitle mb-3">
        {
          post.node.price ?
          post.node.price +' | '+ Number(post.node.currencyPrice.replace('€','')).toFixed(2)+'€' :
          Number(post.node.subscriptionPrice?.replace('zł','')).toFixed(2) +'zł | '+ Number(post.node.currencyPrice.replace('€','')).toFixed(2)+'€'
        }
        </p>
        <br />
        <AddToCartButton product={ post.node } />
        <br />
        </div>
        </>
      )
      })}
      </Slider>
    </>
  );
};

export default SliderDrinks;
