import React from "react";
import { isEmpty } from "lodash";
import Layout from "../../components-pl/layout";
import SingleProduct from "../../components-pl/single-product";
import SEO from "../../components-pl/seo";

const SingleProductPage = (props) => {
  const {
    product,
    product: { name, link, seo },
  } = props.pageContext;

  return (
    <Layout>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={name}
            seoData={seo}
            uri={link}
            header={{ siteTitle: " Sklep" }}
            ogimage={props.pageContext.product.image}
          />
          <SingleProduct product={product} />
        </>
      ) : (
        <div>Coś poszło nie tak...</div>
      )}
    </Layout>
  );
};
export default SingleProductPage;
