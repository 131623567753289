import React from 'react';

export default class ViewItem extends React.Component {

  render() {
    return <></>;
  }

  componentDidMount() {
    const { props } = this.props;
    //console.log(props)
    //console.log(props.id,props.name,props.currencyPrice,props.productCategories.nodes[0].name)

    window.dataLayer.push({
       "event":  "view_item",
       "ecommerce":  {
        "items": [{
          "item_id": props.id,
          "item_name": props.name,
          "price": parseFloat(Number(props.price.replace('zł','')).toFixed(2)),
          "item_brand": "Shroom",
          "item_category": props.productCategories ? props.productCategories.nodes[0].name : '',
        }],
        "currency": "PLN",
      }
    });

  }
}
